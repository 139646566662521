.MuiDrawer-modal .nav-tabs {
	border-bottom: none !important;
}
.MuiDrawer-modal .nav-link {
	font-size: 20px;
	color: #999;
	padding: 20px;
}
.MuiDrawer-modal .nav-link.active {
	font-weight: bold;
	color: #4e4cb3 !important;
}
.MuiDrawer-modal .bar {
	width: 3em;
	height: 3px;
	background: #4e4cb3;
	position: absolute;
	top: 78px;
	left: 119px;
	transition: all 0.2s;
}
.MuiDrawer-modal .MuiInputLabel-formControl {
	position: relative;
}
.MuiDrawer-modal label {
	transition: all 0s !important;
	transform: none !important;
	top: 10px !important;
}
.MuiDrawer-modal .MuiFormControl-fullWidth {
	margin-bottom: -12px;
}

.MuiDrawer-modal input,
.MuiDrawer-modal .MuiInput-input {
	border: 1px solid #d3dae2 !important;
	padding: 10px;
	background: #f8f8f8 !important;
	width: 313px !important;
	border-radius: 4px;
}
.MuiDrawer-modal .MuiInput-underline::before,
.MuiDrawer-modal .MuiInput-underline::after {
	display: none;
}
.MuiDrawer-modal .nav-tabs .nav-link.active,
.MuiDrawer-modal .nav-tabs .nav-item.show .nav-link,
.MuiDrawer-modal .nav-link:hover {
	border: none;

	padding: 20px;
}
.MuiDrawer-modal .nav-tabs .nav-link {
	border: none !important;
}

.MuiDrawer-modal
	.MuiButtonBase-root:not([class*='MuiIconButton-colorPrimary']):not(.MuiListItem-button):not([class*='NotificationsPanel']) {
	/* display: none;*/
}

.MuiDrawer-modal .MuiListItem-gutters.MuiListItem-button {
	padding-left: 0;
	padding-right: 0;
}
.MuiDrawer-modal .MuiSelect-select.MuiSelect-select {
	padding-right: 10px !important;
}
.MuiDrawer-modal .MuiDialogTitle-root {
	display: none;
}
.MuiDrawer-modal div.MuiListItemAvatar-root {
	background: white;
}
.MuiDrawer-modal .MuiAvatar-colorDefault {
	background: #4e4cb3;
}
.MuiDrawer-modal .MuiTypography-root {
	font-size: 20px;
}
.MuiDrawer-modal .MuiListItemText-secondary {
	font-size: 16px;
}
.MuiDrawer-modal .MuiSvgIcon-root {
	font-size: 46px;
}
.MuiDrawer-modal .MuiSelect-select ~ .MuiSvgIcon-root {
	font-size: 25px;
	right: 20px;
}
.MuiFormHelperText-root.Mui-error {
	margin-top: 16px;
}
.MuiDrawer-modal .badge-secondary {
	background: white !important;
	color: #4e4cb3;
	float: right;
	font-weight: normal;
	font-size: 14px;
	position: relative;
	top: 26px;
}
.MuiDrawer-modal .MuiAvatar-root {
	width: 68px;
	height: 68px;
	margin-right: 16px;
}
.MuiDrawer-modal .MuiListItemText-root {
	margin-top: 43px;
	padding-bottom: 18px;
}
.MuiDrawer-modal .userPanel .MuiListItemText-root {
	margin-top: 20px;
}
.MuiDrawer-modal .userPanel span svg {
	transform: scale(1, 0.5);
}
.MuiDrawer-modal div.MuiListItem-gutters .MuiAvatar-root {
	width: 35px;
	height: 35px;
	margin-right: 16px;
}
div.MuiListItem-gutters.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
	margin-top: 0;
	margin-bottom: -56px;
	position: relative;
}
div.MuiListItem-gutters.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button:after {
	position: absolute;
	height: 1px;
	background: #d3dae2;
	width: 100%;
	bottom: 0;
	left: 50px;
	content: '';
}
.MuiDrawer-modal .nav {
	margin-left: 0;
	display: flex;
	justify-content: center;
	padding: 25px;
}
.MuiFormLabel-asterisk {
	color: #dd6363;
	font-size: 22px;
	position: relative;
	top: 6px;
}
.MuiDrawer-modal button {
	width: 100px;
}
.MuiDrawer-modal .MuiGrid-item:last-of-type button {
	border: 1px solid #4e4cb3 !important;
	background: white !important;
	color: #4e4cb3 !important;
}
