.MuiCardContent-root:last-child {
	padding-bottom: 18px !important;
}
.MuiPaper-elevation1 {
	box-shadow: none !important;
}
.recharts-default-legend li {
	display: block !important;
	text-align: left;
	margin-bottom: 21px;
	color: #666;
	font-size: 14px;
}
.recharts-default-legend li svg {
	width: 36px;
	transform: scale(2, 1.1);
}
.recharts-default-legend {
	text-align: right;
	position: absolute;
	right: -30px;
	bottom: 46px;
}
.recharts-pie {
	transform: scale(1.2) translateX(-108px) !important;
}
