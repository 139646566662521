.Navbar {
	background: #4e4cb3;
}
.b {
	border: 1px solid red;
	box-sizing: border-box;
}
.nav {
	margin-left: 48px;
}
.nav a {
	color: rgba(255, 255, 255, 0.5);
	padding: 25px;
	font-size: 16px;
	font-weight: normal;
}
.nav a.activeTab {
	color: white;
	font-weight: bold;
}
.logo {
	width: 160px;
	height: 67px;
}
/* .Select-select-21 .Select-control{
    border-radius: 30px;
} */

@media (max-width: 1415px) and (min-width: 990px) {
	.nav a {
		padding: 10px;
	}
}
