div[style='position: absolute; top: 0px; height: 100%; width: 2px; background: rgb(160, 178, 184); left: 16px;'] {
	background: #4e4cb3 !important;
	opacity: 0.2;
	width: 1px !important;
}
.MuiTypography-root .d-flex:last-of-type {
	position: relative;
}
.MuiTypography-root .d-flex:last-of-type::after {
	content: '';
	position: absolute;
	height: 1px;
	left: -10px;
	right: -10px;
	margin: auto;
	background: #d3dae2;
	bottom: 8px;
	opacity: 0.5;
}
.titleLeftLine {
	display: flex;
	align-items: center;
	font-weight: bold;
}
.titleLeftLine::before {
	content: '';
	width: 4px;
	height: 20px;
	margin-right: 20px;
	background: #4e4cb3;
}
