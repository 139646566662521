.NetworkView .ReactTable .rt-thead.-headerGroups {
	background: none;
	border-bottom: none;
	padding-top: 10px;
}
.NetworkView .ReactTable .rt-th {
	padding: 5px 0 !important;
}
.ReactTable .rt-thead.-header {
	box-shadow: none;
}
.NetworkView .ReactTable .rt-thead .rt-th {
	border-right: none;
}
.NetworkView .rt-th.rt-resizable-header {
	position: relative;
	top: -18px;
}
.NetworkView .rt-th.rt-resizable-header:not(:first-of-type)::after {
	content: '';
	width: 1px;
	height: 76px;
	background: #d3dae2;
	position: absolute;
	left: 0;
	top: -26px;
}
.NetworkView .rt-th.rt-resizable-header:nth-of-type(5)::after {
	top: -43px;
}
.NetworkView .rt-th.rt-resizable-header:nth-of-type(6)::after {
	height: 32px;
	top: 0;
}
.rt-th {
	font-size: 16px;
}
.rt-thead.-headerGroups:first-child .rt-th:nth-of-type(2) {
	border-bottom: 1px solid #d3dae2;
}
.rt-thead.-header .rt-tr {
	border-bottom: 1px solid #d3dae2;
}
.NetworkView .rt-th.rt-resizable-header:nth-of-type(5),
.NetworkView .rt-th.rt-resizable-header:nth-of-type(7),
.NetworkView .rt-th.rt-resizable-header:nth-of-type(6) {
	top: 0;
}
.ReactTable .rt-thead.-filters input {
	margin: 10px auto;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	background: #f8f8f8;
}
.rt-td {
	text-align: center !important;
}
.-pageInfo {
	position: relative;
}
.-pageInfo::after {
	content: '当前页:';
	position: absolute;
	background: white;
	left: -12px;
	top: 6px;
}
.-pageInfo .-totalPages {
	padding-left: 45px;
}
.-pageJump {
	position: relative;
}
.-pageJump::after {
	content: '总页数:';
	position: absolute;
	background: white;
	right: -50px;
	top: 6px;
}
.-previous .-btn,
.-next .-btn {
	position: relative;
	background: #d3dae2 !important;
}
.-previous .-btn:hover,
.-next .-btn:hover {
	background: #4e4cb3 !important;
}
.-previous .-btn:after,
.-next .-btn:after {
	content: '上一页';
	background: inherit;
	position: absolute;
	padding: 0 50px;
	left: 0;
	right: 0;
	margin: auto;
}
.-next .-btn:after {
	content: '下一页';
}
.select-wrap.-pageSizeOptions option {
	position: relative;
}
