@import '~antd/dist/antd.css';
.blockA {
	position: absolute !important;
	top: 196px;
	right: 10px;
	font-size: 14px;
	color: #4e4cb3;
	font-weight: normal;
}
.ant-picker {
	position: relative;
	top: -5px;
	border-radius: 4px;
	height: 38px;
	line-height: 38px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #4e4cb3;
}
.ant-tabs-tab-active {
	font-weight: bold;
}
div[style*='45px']
	div[style*='position: absolute; top: 0px; border-radius: 50%; width: 30px; height: 30px; margin-left: 1px; background: rgb(233, 240, 245); border: 2px solid rgb(78, 76, 179); display: flex; color: rgb(78, 76, 179); left: 0px;'] {
	background: #4e4cb3 !important;
}
div[style*='background-color: white; padding: 10px; color: rgb(78, 76, 179); font-size: 13pt;'] {
	position: relative;
	top: -8px;
	padding-left: 0 !important;
}
.ant-tabs-ink-bar {
	background: #4e4cb3;
	bottom: 9px !important;
}
.jdmc .rt-resizable-header {
	display: none !important;
}
.jdmc .rt-thead {
	display: none !important;
}
.jdmc .rt-tbody {
	padding-top: 2px;
}
div.footer {
	margin: 0;
	margin-top: 16px;
	margin-bottom: 16px;
	background-color: #f0f5f9 !important;
	opacity: 0.4;
	position: relative;
}
.multi-select .dropdown-heading-value {
	line-height: 38px !important;
}
.btn-success {
	border: none !important;
}
.btn-primary {
	background: white !important;
	color: #4e4cb3 !important;
	border-color: #4e4cb3 !important;
}
.searchRow button {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;

	box-sizing: border-box;
}
.searchRow .col-md-1 {
	margin-top: 12px !important;
}
.multi-select .dropdown-heading {
	height: 38px !important;
	margin-top: -5px;
	text-align: left;
	border: 1px solid #d9d9d9 !important;
}
.ReactTable a {
	color: #4e4cb3 !important;
}
.ant-notification {
	z-index: 22222222;
}
div[class*='-filter-'] {
	margin-bottom: 10px !important;
}
.b {
	border: 1px solid red;
	box-sizing: border-box;
}
.viewPort {
	width: 80%;
	margin-left: 10%;
	margin-right: 10%;
}
.d-flex {
	display: flex;
}
.flex-1 {
	flex: 1;
}
.a-center {
	align-items: center;
}
.j-sb {
	justify-content: space-between;
}
.j-center {
	justify-content: center;
}
.j-center .ant-tabs-nav-wrap {
	justify-content: center;
}
.j-center .ant-tabs-nav::before {
	border: none;
}
.j-center .ant-tabs-nav {
	margin: 0;
}
.w100 {
	width: 100% !important;
}
.mb-0 {
	margin-bottom: 0;
}
.mb-1 {
	margin-bottom: 10px;
}
.mt-1 {
	margin-top: 10px;
}
.p-b-2 {
	padding-bottom: 20px;
}
.p-0 {
	padding: 0;
}
.p-0 .ant-card-body {
	padding: 0;
}
.pl-0 {
	padding-left: 0;
}
.pl-30 {
	padding-left: 30px;
}
.pr-0 {
	padding-right: 0;
}

.color6 {
	color: #666;
}
.color9 {
	color: #999;
}
.text-center {
	text-align: center;
}
.text14 {
	font-size: 14px;
}
.text16 {
	font-size: 16px;
}
.text18 {
	font-size: 18px !important;
}

.textwrap {
	word-break: break-all;
}

.bgColor {
	background-color: rgb(240, 245, 249);
}

a:hover {
	text-decoration: none !important;
}

.ReactTable .rt-thead .rt-th {
	border-right: 1px solid #d3dae2;
}
.ReactTable.-striped.-highlight {
	border-color: #d3dae2 !important;
}
.ReactTable .rt-tr-group {
	flex: 0 !important;
}

button.register-cancel,
button.register-ok {
	box-shadow: none !important;
	box-sizing: border-box;
}
button.register-ok {
	padding: 7px;
}
button.btn-success {
	background: #4e4cb3 !important;
	opacity: 1 !important;
}
button.btn-success:hover,
button.btn-success:active,
button.btn-success:focus,
button.register-ok:hover,
button.register-ok:active,
button.register-ok:focus {
	background: #292880 !important;
	opacity: 1 !important;
}
button.btn-primary:hover,
button.btn-primary:active,
button.btn-primary:focus {
	color: #292880 !important;
	border-color: #292880 !important;
}
button,
button:active {
	box-shadow: none !important;
}
div[style='background-color: transparent; margin-bottom: 1em; line-height: 1.6; padding: 10px; min-height: 40px;'] {
	padding-bottom: 12px !important;
	padding-left: 16px !important;
	padding-right: 16px !important;
}
.dropdown-item:active {
	background: none !important;
}
div[class*='Modal-dialog'] {
	border-width: 0 !important;
}
.qkxx time {
	position: relative;
	top: 4px;
}
.card-title a:hover {
	color: #666;
}
